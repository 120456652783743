<template>
  <er-card
    v-loading="loading"
    element-loading-background="white"
    ref="pond-lab-test-graph"
    class="lab-test-chart"
  >
    <el-row :key="reRenderFunction()">
      <high-charts
        ref="highcharts"
        :options="chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
  import { mapGetters } from "vuex";
  import errorHandlerMixin from "@/mixins/errorHandlerMixin";
  import filtersMixin from "@/mixins/filtersMixin";
  import { labTestsGraph } from "./graphs/chartOptions";
  import userPreferenceMixin from "@/mixins/userPreferenceMixin";

  export default {
    mixins: [errorHandlerMixin, filtersMixin, userPreferenceMixin],
    props: ["labField"],
    data: function () {
      return {
        chartOptions: { ...labTestsGraph },
        loading: false
      };
    },
    computed: {
      ...mapGetters("pondDetails", {
      getSelectedPondLabTests: "getSelectedPondLabTests"
    }),
    },
    async mounted() {
      await this.initComponent();
    },
    watch: {
      getSelectedPondLabTests: {
        handler(value) {
          this.initComponent();
        },
        deep: true,
      },
      labField: {
        handler(value) {
          this.initComponent();
        },
        deep: true,
      },
    },
    methods: {
    reRenderFunction() {
      return (Math.random() * 10 + 10).toFixed(3);
    },
      async initComponent() {
        this.loading = true;
        try {
          await this.initChart();
        } catch (error) {
          this.ehm__errorMessages(error, true);
        } finally {
          this.loading = false;
        }
      },
    async initChart() {
      this.chartOptions.series = [];
      let data = [];
        await this.$lodash.cloneDeep(this.getSelectedPondLabTests).map((curr) => {
          const date = new Date(curr.date).getTime();
          data.push({ x: date, y: curr[this.labField.value] || null, z: curr.doc })
        });
        data = data.sort((a, b) => a.x - b.x);

       this.chartOptions.series[0] = {
          data: data,
          name: {
          tooltip: this.labField.label,
          tooltip1: this.$t('Comn_doc'),
        },
          type: "spline",
          pointWidth: 10,
          visible: true
        };
        this.chartOptions.yAxis[0].title.text = this.labField.label;
      }
    },
  };
  </script>

  <style lang="scss">
    .market-chart {
      @include responsiveProperty(height, 66vh, 66vh, 50vh);
      &__container {
        height: 100%;
        width: 100%;
        &__highcharts {
          height: 100%;
          width: 100%;
        }
      }
    }
  </style>
