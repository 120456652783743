<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: abw.vue
Description:This file gives the information about average body weight of the shrimps in a pond
-->
<template>
  <abw :permissions="getComponentPermissions"></abw>
</template>

<script>
import abw from "@/components/culture/abw";
import { permissionsToStringMap as permissions } from "@/middleware/pageAccessManager";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
export default {
  components: { abw },
  computed: {
    getComponentPermissions () {
      return {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_ABW]
      };
    }
  },
  beforeRouteEnter (to, from, next) {
    handleStoreRegistration(to, from);
    next();
  }
};
</script>

<style></style>
