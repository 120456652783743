var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],key:_vm.$i18n.locale,ref:"table",attrs:{"el-table-props":_vm.tableProps,"tableData":_vm.tableAbwRecords,"columns":_vm.tableColumns,"action-column":false,"uniqueId":"pond-abw-tab","type":"white-header-table","size":"mini","columnSelector":true},scopedSlots:_vm._u([{key:"doc",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.data.day_of_culture))]}},{key:"biomass",fn:function(ref){
var row = ref.row;
return [_c('div',{directives:[{name:"upm__units",rawName:"v-upm__units.digitPrecision",value:(_vm.getBiomassUnits(row.data)),expression:"getBiomassUnits(row.data)",modifiers:{"digitPrecision":true}}]})]}},{key:"survival_percnt",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(_vm.ftm__formatEmpty(_vm.getSurvivalPercentage(row.data)))+"\n  ")]}},{key:"adg",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.adg, 2))))]}},{key:"event_date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"color":"#155dbe"}},[_vm._v(_vm._s(_vm.getEventDate(row.data.date)))])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('er-button',{attrs:{"type":"text","size":"mini","btnType":"edit","showLabel":true,"showIcon":true,"disabled":_vm.disabledBtn(row.data, 'EDIT')},on:{"click":function($event){return _vm.handleEditABWRecord(row.data)}}}),_vm._v(" "),_c('el-divider',{staticStyle:{"height":"100%"},attrs:{"direction":"vertical"}}),_vm._v(" "),_c('er-button',{attrs:{"type":"text","size":"mini","btnType":"delete","showLabel":true,"showIcon":true,"disabled":_vm.disabledBtn(row.data, 'DELETE')},on:{"click":function($event){return _vm.handleDeleteABWRecord(row.data)}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }