var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-pondlogs',{staticClass:"culture-abw",attrs:{"headerType":"card","containerType":"card"}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t("ABW_abw")))]),_vm._v(" "),_c('template',{slot:"header"},[_c('layout-toolbar',[_c('er-single-day-picker',{attrs:{"value":_vm.abwDate,"value-format":"yyyy-MM-dd","format":this.upm__getFormatDateString},on:{"change-prev-day":_vm.prevDayClick,"change-next-day":_vm.nextDayClick,"change-day":_vm.handlePresentDateChange}}),_vm._v(" "),_c('el-col',{attrs:{"span":4.3}},[_c('er-input',{attrs:{"inputType":"search","size":"mini","placeholder":_vm.$t('Ponds_select_pond_name'),"suffix-icon":"el-icon-search"},on:{"change":_vm.handelSearchInput},model:{value:(_vm.searchPond),callback:function ($$v) {_vm.searchPond=$$v},expression:"searchPond"}})],1)],1)],1),_vm._v(" "),_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"layout-pondlogs-scrollable-main","element-loading-background":"white"},slot:"layout-pondlogs-scrollable-main"},[(_vm.loading)?_c('Loader'):_vm._e(),_vm._v(" "),(!_vm.loading || _vm.tableDataUpdated.length > 0)?_c('el-row',[_c('er-data-tables',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:_vm.$i18n.locale,ref:"abwtable",attrs:{"tableData":_vm.tableDataUpdated,"columns":_vm.tableColumns,"type":"white-header-table","uniqueId":"abw-table","action-column":false,"el-table-props":_vm.tableProps,"selectColumn":true,"pagination-slot":true,"sortMethodForColumns":_vm.sortMethodForColumns,"searchOnTableData":_vm.searchPond},scopedSlots:_vm._u([{key:"selected_header",fn:function(ref){
var $index = ref.$index;
return [_c('el-checkbox',{key:$index,attrs:{"value":_vm.checkAll,"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleSelectAll}})]}},{key:"selected",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{on:{"change":function($event){return _vm.handleChangeABW(row.data, 'checkBox')}},model:{value:(row.data.selected),callback:function ($$v) {_vm.$set(row.data, "selected", $$v)},expression:"row.data.selected"}})]}},{key:"pond_name",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"pond_title","withoutHighlightStrings":row.data.pond_title}})]}},{key:"last_abw",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.last_abw === 0
              ? "--"
              : _vm.getValuetoFixed(row.data.last_abw))+"\n        ")]}},{key:"last_abw_date",fn:function(ref){
              var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.last_abw_date === "--"
              ? "--"
              : _vm.getABWLastDate(row.data.last_abw_date))+"\n        ")]}},{key:"samples",fn:function(ref){
              var row = ref.row;
return [_c('samples-table2',{attrs:{"isDisabled":row.data.harvested_abw,"popover-data":row.data,"hasUpdatePermission":_vm.hasUpdatePermissions},on:{"calculated":_vm.handelCalculate,"cancelled":_vm.handleSampleTablePopverCancel}})]}},{key:"abw_g",fn:function(ref){
              var row = ref.row;
return [_c('el-input-number',{staticClass:"input_abw",attrs:{"controls":false,"precision":2,"min":0,"size":"mini","disabled":row.data.harvested_abw},on:{"blur":function($event){return _vm.handleChangeABW(row.data)},"change":function($event){return _vm.handleChangeInABW($event, row.data)}},model:{value:(row.data.abw),callback:function ($$v) {_vm.$set(row.data, "abw", $$v)},expression:"row.data.abw"}})]}},{key:"weight_gain",fn:function(ref){
              var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__digitPrecision(row.data.weight_gain, 2))+"\n          ")]}},{key:"survival_percentage",fn:function(ref){
              var row = ref.row;
return [_c('el-input-number',{staticClass:"input_survival_per",attrs:{"controls":false,"precision":0,"size":"mini","min":0,"disabled":row.data.harvested_abw},on:{"blur":function($event){return _vm.handleChangeABW(row.data, 'survival')}},model:{value:(row.data.survival_percentage),callback:function ($$v) {_vm.$set(row.data, "survival_percentage", $$v)},expression:"row.data.survival_percentage"}})]}},{key:"days_of_culture",fn:function(ref){
              var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(_vm.getDOC(row.data.cultivation_date)))+"\n        ")]}}],null,false,98982282)},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_c('el-row',{staticClass:"btns-container",attrs:{"slot":"pagination","type":"flex"},slot:"pagination"},[_c('er-button',{attrs:{"btnType":"save","showIcon":true,"disabled":_vm.isReadOnly,"loading":_vm.loading,"showLabel":true,"size":"mini"},on:{"click":_vm.saveSelectedABWDetails}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","showLabel":true,"size":"mini","disabled":_vm.isReadOnly},on:{"click":_vm.cancelSelectedABWDetails}})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.harvestedPonds.length > 0)?_c('span',[_c('el-alert',{attrs:{"title":((_vm.$t('ABW_hrvst_completed_ponds_list')) + ": " + (_vm.harvestedPonds.length)),"type":"info","show-icon":"","closable":false}},[_c('h4',[_vm._v(_vm._s(_vm.harvestedPonds.join(", ")))])]),_vm._v(" "),_c('el-col',[_c('el-row',{staticClass:"abbr-row"},[_c('h6',{domProps:{"innerHTML":_vm._s(_vm.$t('ABW_abbr'))}})])],1)],1):_vm._e(),_vm._v(" "),_c('el-row',{staticClass:"abbr-row"},[_c('h6',{domProps:{"innerHTML":_vm._s(_vm.$t('abw_note'))}})])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }