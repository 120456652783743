<template>
  <!-- <portal to="pond-details-top-nav"> -->
  <layout-toolbar>
    <er-date-picker
      :key="selectedPondDetails._id"
      :value="getDateRange"
      value-format="yyyy-MM-dd"
      :timeZoneString="getUserTimeZoneString"
      :format="upm__getFormatDateString"
      type="daterange"
      :clearable="false"
      :disableDateMethod="disableDateMethod"
      :availableInterval="availableInterval"
      @input="handleChangeInDateRange"
    ></er-date-picker>
    <div class="filler"></div>
    <!-- <el-col :span="3">
      <er-select v-model="selectedView" size="mini">
        <el-option value="list" label="List"></el-option>
      </er-select>
    </el-col> -->
  </layout-toolbar>
  <!-- </portal> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
export default {
  mixins: [errorHandlerMixin, datesHandlerMixin],
  data() {
    return {
      selectedView: "list"
    };
  },
  watch: {
    getDateRange: async function(newValue, oldValue) {
      await this.handleFetchingManualABWRecords();
    }
  },
  computed: {
    ...mapGetters("pondDetails", {
      getPondIdPondMap: "getPondIdPondMap",
      pondId: "getSelectedPondId",
      getCurrentCulture: "getCurrentCulture",
      getRunningCultureForSelectedPond: "getRunningCultureForSelectedPond",
      getArrPrevCulturesForSelectedPond: "getArrPrevCulturesForSelectedPond"
    }),
    ...mapGetters("pondDetails/pondABWTab", {
      getDateRange: "getDateRange"
    }),
    selectedPondDetails() {
      return this.getPondIdPondMap[this.pondId];
    },
    availableInterval() {
      return {
        start: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.start_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        ),
        end: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.end_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        )
      };
    },
    getCultureDateRange() {
      return [
        this.getCurrentCulture.start_date,
        this.getCurrentCulture.end_date
      ];
    },
    getDateObjArrOfCultureDateRange() {
      const start_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getCultureDateRange[0],
        this.getUserTimeZoneString
      );
      const end_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getCultureDateRange[1],
        this.getUserTimeZoneString
      );
      return [start_date, end_date];
    }
  },
  async mounted() {
    await this.initDate();
  },
  methods: {
    ...mapActions("pondDetails/pondABWTab", {
      changeDateRange: "changeDateRange",
      fetchManualABWRecords: "fetchManualABWRecords"
    }),
    disableDateMethod: function(time) {
      const currDate = time;
      const paramObj = {
        dateRange: this.getDateObjArrOfCultureDateRange,
        date: currDate,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      };
      return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
    },
    async initDate() {
      const endDate = this.dhm__formatByTZOnUTCISOWithLocale(
        this.getCurrentCulture.end_date,
        "yyyy-MM-dd"
      );
      const startDate = this.dhm__formatByTZOnUTCISOWithLocale(
        this.getCurrentCulture.start_date,
        "yyyy-MM-dd"
      );
      const dateRangeSelected = [startDate, endDate];
      this.changeDateRange(dateRangeSelected);
    },
    async handleChangeInDateRange(dateRange) {
      this.changeDateRange(dateRange);
      this.$emit('date-change', true);
    },
    async handleFetchingManualABWRecords() {
      try {
        await this.fetchManualABWRecords();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
