<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: noCultures.vue
Description: This file contains component which is the useful informing the user that the pond does not have culture and asks user to perform appropriate actions
-->
<template>
  <er-card class="no-location">
    <span>
      {{
        $t("error_messages.item.empty", {
          item: $tc("Comn_culture.lowercase", 2),
          source: $tc("Comn_pond", 3),
        })
      }}
    </span>
    <i18n path="please_do_this_action_to_get_result" :tag="false">
      <template v:slot:action>
        {{ $t("actions.click.lowercase") }}
      </template>
      <template v-slot:get_result>
        <router-link
          :to="{
            path: 'settings',
            query: { location_id: $route.query.location_id, tab: 'pondTab' },
          }"
        >
          {{ $t("start") }}
          {{ $tc("Comn_culture.plural_with_articles", 1) }}
        </router-link>
      </template>
    </i18n>
  </er-card>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";

export default {
  name: "NoPonds",
  mixins: [errorHandlerMixin, redirectsMixin],
  props: {
    tabData: {
      default: () => ({})
    },
    disabled: {
      default: false
    }
  },
  methods: {
    handleAddPondClick() {
      try {
        this.rdm__navigateToPathWithLocationId({ path: "map-view" });
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.no-location {
  display: block;
  text-align: center;
  background: white;
  #info {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .el-button {
    p {
      text-transform: uppercase;
    }
  }
}
</style>
