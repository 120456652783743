<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondABWTab.vue
Description: This file contians UI components used to show ABW records in pond page of overview section. It contians data table showing ABW records
-->
<template>
  <el-row class="pond-abw-tab">
    <abw-toolbar @date-change="handelDateChange"></abw-toolbar>
    <er-card
      class="card-abw-details"
      :data-card-items="ftgm__isFeatureAvailable(shrimpSnapFeatureReq) ? 2 : 1"
    >
      <splitpanes
        height="100%"
        ref="cardBodySplitpanes"
        horizontal
        class="default-theme"
        @resized="handlePaneResizedEvent"
      >
        <pane>
          <abw-table
            :permissions="tabData"
            class="card-item card-item__manual-abw"
          ></abw-table>
        </pane>
        <pane v-if="ftgm__isFeatureAvailable(shrimpSnapFeatureReq)">
          <div class="card-item card-item__ss-abw">
            <ss-toolbar ref="ssToolbar"></ss-toolbar>
            <component :is="ssViewToComponent[selectedSSView]"></component>
          </div>
        </pane>
      </splitpanes>
    </er-card>
    <abw-form-dialog></abw-form-dialog>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import abwToolbar from "@/components/pond/pondABWTab/abwToolbar";
import abwTable from "@/components/pond/pondABWTab/abwTable";
import abwFormDialog from "@/components/pond/pondABWTab/abwFormDialog";
import featureToggleMixin from "@/mixins/featureToggleMixin";
import { Splitpanes, Pane } from "splitpanes";
import hashSum from "hash-sum";
import "splitpanes/dist/splitpanes.css";
export default {
  provide() {
    return {
      parentConfig: this
    };
  },
  props: ["tabData"],
  components: {
    abwToolbar,
    abwTable,
    abwFormDialog,
    Splitpanes,
    Pane,
    ssABWImageView: () => import("@/components/pond/pondABWTab/ssABWImageView"),
    ssToolbar: () => import("@/components/pond/pondABWTab/shrimpSnapToolbar"),
    ssTable: () => import("@/components/pond/pondABWTab/ssTable"),
    ssABWGraph: () => import("@/components/pond/pondABWTab/ssABWGraph")
  },
  mixins: [
    errorHandlerMixin,
    notificationMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    featureToggleMixin,
    errorHandlerMixin
  ],
  data: function() {
    const panesCount = 2;
    return {
      showDialog: false,
      loading: false,
      tableData: [],
      initSplitPaneHeight: 1 / panesCount,
      ssViewToComponent: {
        LIST: "ssTable",
        IMAGE: "ssABWImageView",
        GRAPH: "ssABWGraph"
      }
    };
  },
  watch: {
    getSelectedSS: async function(newValue, oldValue) {
      if (newValue === undefined || newValue.device_code === undefined) return;
      if (hashSum(newValue) === hashSum(oldValue)) return;
      await this.initShrimpSnapView();
    },
    getDateRange: async function(newValue, oldValue) {
      if (hashSum(newValue) === hashSum(oldValue)) return;
      await this.initShrimpSnapView();
    }
  },
  computed: {
    ...mapGetters("pondDetails", {
      getPondIdPondMap: "getPondIdPondMap",
      getSelectedPondId: "getSelectedPondId",
      getArrSelectedPondABWRecords: "getArrSelectedPondABWRecords",
      getCurrentCulture: "getCurrentCulture",
      getSelectedPondName: "getSelectedPondName"
    }),
    ...mapGetters("pondDetails/pondABWTab", {
      getLoadingComponents: "getLoadingComponents",
      getSelectedSS: "getSelectedSS",
      selectedSSView: "getSelectedSSView",
      getDateRange: "getDateRange"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getUserCountryCode: "getUserCountryCode",
      getUserTimeZoneString: "getUserTimeZoneString",
      getPreferredUnits: "getPreferredUnits",
      getCurrUserLocation: "getCurrUserLocation"
    }),
    maxHeightToPanes: function() {
      return {
        1: this.initPaneHeightInPx,
        2: this.initPaneHeightInPx
      };
    },
    shrimpSnapFeatureReq: function() {
      return {
        featureAllowedUsers: [
          "5e1897b5188b23258b34654d",
          "5e565a0cbc2c4dd8b5f6f041",
          "5f1926e917a2492077983958",
          "5e5cfb322c599d13d25b607e",
          "5e1897b5188b23258b346396",
          "5e1897b5188b23258b3465c3",
          "5e1897b5188b23258b34637e",
          "5e1897b5188b23258b34638e",
          "5e565a0cbc2c4dd8b5f6f04c",
          "5ff6cf2016578b2c1c93c940",
          "61331ae3bc214f7f70e834ad"
        ],
        currUser: this.getUserProfile
      };
    },
    selectedPondDetails: function() {
      return this.getPondIdPondMap[this.getSelectedPondId];
    },
    initialABW: function() {
      if (!this.selectedPondDetails.initial_abw_id) {
        return 0;
      }
      return this.selectedPondDetails.initial_abw_id.abw || 0;
    },
    cardBodySplitpanesHeight() {
      if (this.$refs.cardBodySplitpanes) {
        return this.$refs.cardBodySplitpanes.$el.getBoundingClientRect().hegiht;
      }
      return 0;
    },
    ssToolbarHeight() {
      if (this.$refs.ssToolbar) {
        return this.$refs.ssToolbar.$el.getBoundingClientRect().height;
      }
      return 0;
    },
    initPaneHeightInPx() {
      return this.cardBodySplitpanesHeight * this.initSplitPaneHeight;
    }
  },
  async mounted() {
    await this.initShrimpSnapView();
  },
  methods: {
    ...mapActions("pondDetails/pondABWTab", {
      fetchShrimpSnapsImageData: "fetchShrimpSnapsImageData"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelDateChange(date) {
      this.mixPanelEventGenerator({ eventName: "Ponds - ABW - Date Filter" });
    },
    handleSplitPanesReadyEvent() {
      console.log(this.$refs.cardBodySplitpanes.$el.getBoundingClientRect());
    },
    handlePaneResizedEvent(event) {
      console.log(
        event,
        arguments,
        this.$refs.cardBodySplitpanes.$el.getBoundingClientRect()
      );
    },
    isLoading() {
      return this.getLoadingComponents.size > 0;
    },
    async initShrimpSnapView() {
      try {
        this.loading = true;
        if (!this.ftgm__isFeatureAvailable(this.shrimpSnapFeatureReq)) {
          this.loading = false;
          return;
        }
        await this.fetchShrimpSnapsImageData();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pond-abw-tab {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;
  .card-abw-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    .splitpanes.default-theme .splitpanes__pane {
      background-color: unset;
    }
    &::v-deep .el-card__body {
      height: calc(100% - 13px);
    }
    &[data-card-items="1"] {
      @include responsiveProperty(--height-gutter, 290px, 280px, 300px);
      .card-item {
        height: 100%;
        overflow: auto;
      }
    }
    &[data-card-items="2"] {
      @include responsiveProperty(--height-gutter, 440px, 515px, 680px);
      .card-item__ss-abw {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 0;
        height: 100%;
        overflow: auto;
      }
    }
  }
  &::v-deep .el-input__inner {
    text-align: center;
  }
  &::v-deep .el-select,
  &::v-deep .el-input-number {
    width: 100%;
  }
  div + div {
    margin-top: 7px;
  }
}
</style>
