<template>
  <er-data-tables
    ref="table"
    :el-table-props="tableProps"
    v-loading="isLoading"
    :tableData="tableAbwRecords"
    :columns="tableColumns"
    :action-column="false"
    uniqueId="pond-abw-tab"
    type="white-header-table"
    size="mini"
    :columnSelector="true"
    :key="$i18n.locale"
  >
    <template v-slot:doc="{ row }">{{ row.data.day_of_culture }}</template>
    <template v-slot:biomass="{ row }">
      <div v-upm__units.digitPrecision="getBiomassUnits(row.data)"></div>
    </template>
    <template v-slot:survival_percnt="{ row }">
      {{ ftm__formatEmpty(getSurvivalPercentage(row.data)) }}
    </template>
    <template v-slot:adg="{ row }">{{
      ftm__formatEmpty(ftm__digitPrecision(row.data.adg, 2))
    }}</template>
    <template v-slot:event_date="{ row }">
      <span style="color: #155dbe">{{ getEventDate(row.data.date) }}</span>
    </template>
    <template v-slot:actions="{ row }">
      <el-row type="flex" align="middle">
        <er-button
          type="text"
          size="mini"
          btnType="edit"
          :showLabel="true"
          :showIcon="true"
          :disabled="disabledBtn(row.data, 'EDIT')"
          @click="handleEditABWRecord(row.data)"
        ></er-button>
        <el-divider direction="vertical" style="height: 100%"></el-divider>
        <er-button
          type="text"
          size="mini"
          btnType="delete"
          :showLabel="true"
          :showIcon="true"
          :disabled="disabledBtn(row.data, 'DELETE')"
          @click="handleDeleteABWRecord(row.data)"
        ></er-button>
      </el-row>
    </template>
  </er-data-tables>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import { mapActions, mapGetters } from "vuex";
import { Samples } from "@/model/abw";
export default {
  props: ["permissions"],
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    datesHandlerMixin,
    notificationMixin
  ],
  data() {
    return {
      tableProps: {
        height: "calc(100vh - var(--height-gutter))"
      }
    };
  },
  watch: {
    tableAbwRecords: function () {
      this.$nextTick(() => {
        if (this.$refs.table) {
          console.log(this.$refs.table.$refs.dataTables.$refs.elTable);
          this.$refs.table.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    }
  },
  computed: {
    ...mapGetters("pondDetails", {
      getPondIdPondMap: "getPondIdPondMap",
      getSelectedPondId: "getSelectedPondId",
      getCurrentCulture: "getCurrentCulture"
    }),
    ...mapGetters("pondDetails/pondABWTab", {
      tableAbwRecords: "getManualABWRecords",
      getLoadingComponents: "getLoadingComponents"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    isLoading() {
      return this.getLoadingComponents.has("ABW_TABLE");
    },
    selectedPondDetails: function () {
      return this.getPondIdPondMap[this.getSelectedPondId];
    },
    getSurvivalPercentage() {
      return (abwData) => {
        if (!abwData) return undefined;
        // if (abwData.harvested_abw) return undefined;
        return abwData.survival_percentage;
      };
    },
    getEventDate() {
      return (date) => {
        return this.dhm__formatByTZOnUTCWithLocale(
          date,
          this.upm__getFormatDateAndTimeString
        );
      };
    },
    tableColumns: function () {
      return {
        doc: {
          prop: "day_of_culture",
          label: this.$t("Comn_doc"),
          minWidth: 80,
          required: true
        },
        abw: {
          prop: "abw",
          label: this.$t("abw_gms"),
          minWidth: 100,
          required: true
        },
        awg: {
          prop: "awg",
          label: this.$t("Comn_awg_g"),
          // width: "100px",
          required: true
        },
        adg: {
          prop: "adg",
          label: this.$t("Comn_adg_g"),
          // width: "100px",
          required: true
        },
        biomass: {
          prop: "biomass",
          label: this.$t("Comn_biomass"),
          sub_label: "(" + this.getPreferredUnits.biomass_units + ")",
          required: true
        },
        survival_percnt: {
          prop: "survival_percentage",
          label: this.$t("Comn_survival_percentage"),
          required: true
        },
        fcr: {
          prop: "fcr",
          label: this.$t("Comn_fcr"),
          required: true
        },
        event_date: {
          prop: "date",
          label: this.$t("Medicines_event_date"),
          required: true
        },
        samples_weight: {
          label: this.$t("ABW_samples_weight"),
          required: false
        },
        samples: {
          label: this.$t("Comn_samples"),
          required: false
        },
        actions: {
          label: this.$t("Comn_actions"),
          required: true,
          minWidth: 100
        }
      };
    },
    getBiomassUnits: function () {
      return (abwRec) => {
        const pl_stocked = (
          this.getPondIdPondMap[this.getSelectedPondId] || {
            post_larva_stocked: 0
          }
        ).post_larva_stocked;

        const sur_percnt = abwRec.survival_percentage * 0.01;

        const abw = abwRec.abw * 0.001;

        const totalBiomassandUnits = {
          value: pl_stocked * sur_percnt * abw || 0,
          previousunits: this.upm__backendUnits.biomass_units,
          units: this.getPreferredUnits.biomass_units,
          modifiersParams: { digitPrecision: [0] },
          displayFormatter: () => {
            return ``;
          }
        };

        return totalBiomassandUnits;
      };
    }
  },
  methods: {
    ...mapActions("pondDetails", {
      deleteABWRecord: "deleteABWRecord"
    }),
    ...mapActions("pondDetails/pondABWTab", {
      changeABWRecActionDialogVisibilityStatus:
        "changeABWRecActionDialogVisibilityStatus",
      changeABWRecActionDialogMode: "changeABWRecActionDialogMode",
      changeABWRecActionDialogModel: "changeABWRecActionDialogModel",
      fetchManualABWRecords: "fetchManualABWRecords"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),

    async handleDeleteABWRecord(abwRec) {
      const pond_name = this.selectedPondDetails.title;
      try {
        this.$gblUAMCanUserDelete(this.permissions);
        await this.nofm__deleteConfirmation(this.$t("ABW_abw"), pond_name);
        await this.deleteABWRecord(abwRec._id);
        this.mixPanelEventGenerator({ eventName: "Ponds - ABW - Table Data - Delete" });
        await this.fetchManualABWRecords();
        this.nofm__notifySuccess({
          msgLangkey: {
            title: "Comn_delete_success_msg",
            count: 0,
            child: {
              item: this.$t("ABW_abw"),
              source: pond_name
            }
          }
        });
      } catch (err) {
        if (err !== "cancel") {
          this.ehm__errorMessages(err, true);
        }
      }
    },
    disabledBtn(abwData, btnType) {
      const conditions = [
        this.isReadOnly,
        !this.getCurrentCulture.is_running,
        btnType === "EDIT" && abwData.harvested_abw,
        btnType === "DELETE" && abwData.harvested_abw,
        btnType === "DELETE" && abwData.deletable === false
      ];
      return conditions.includes(true);
    },
    handleEditABWRecord(abwRec) {
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        this.changeABWRecActionDialogMode("EDIT");
        const editABWObj = JSON.parse(JSON.stringify(abwRec));
        editABWObj.dateStr = editABWObj.date
          ? this.dhm__formatByTZOnUTCWithLocale(
              editABWObj.date,
              this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_YEAR
            )
          : "";
        if (editABWObj.samples.length === 0) {
          editABWObj.samples = [new Samples()];
        }
        this.changeABWRecActionDialogModel(editABWObj);
        this.changeABWRecActionDialogVisibilityStatus(true);
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
