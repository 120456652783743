<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: stStatusGraph.vue
Description: This file is the chart component that display amount of feed dispensed in relation with response from the hydrophone. Here x axis value ranges from 0 to 24 hours, left y axis is the amount of feed dispsensed in kilograms, right y axis is the response quality from the shirmptalk.
-->
<template>
  <er-card
    v-loading="getStGraphLoading"
    element-loading-background="white"
    class="st-status-graph"
    ref="st-status-graph"
  >
    <layout-toolbar slot="header" justify="start" align="center">
      <p class="card-title">
        {{ `${$t("Comn_hourly_feed")} vs ${$t("Comn_response")}` }}
      </p>
      <er-select
        :key="pondId"
        v-model="selectStId"
        @change="handleChangeInSt"
        size="mini"
        :placeholder="$t('Comn_select_shrimptalks')"
      >
        <!-- v-if="listPondGuards>1" -->
        <el-option
          v-for="st in listShrimpTalks"
          :key="st.code"
          :value="st._id"
          :label="upm__getValue(st)"
        ></el-option>
      </er-select>
      <div class="filler"></div>
      <div>
        <el-date-picker
          class="date-picker-wrapper"
          size="mini"
          type="date"
          v-model="dateRange[1]"
          :value-format="dateValueFormat"
          :format="upm__getFormatDateString"
          :picker-options="pickerOptions"
          :clearable="false"
          @change="handleValueChange( $event, 'dateChange')"
        ></el-date-picker>
      </div>
    </layout-toolbar>

    <el-row :key="$i18n.locale">
      <high-charts
        :options="chartOptions"
        ref="highcharts"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import chartMixin from "@/mixins/chartMixin";
import { stGraphConfig } from "./chartOptions";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
// import { generateColorForModeInGraph } from "@/utils/componentColors";
import dateUtils from "@/utils/dateUtils";
export default {
  mixins: [
    errorHandlerMixin,
    chartMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      dateValueFormat: "yyyy-MM-dd",
      feed_hourly_logs: [],
      dataViewOptions: "Raw Data",
      dateRange: [],
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null
      },
      chartOptions: { ...stGraphConfig },
      selectStId: "",
      listShrimpTalks: [],
      groupByStsId: {},
      STResponseData: [],
      hourlyResultData: {},
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y"
      },
      timeFormatObject: {
        "HH:mm": "%H:%M",
        "hh:mm a": "%l:%M %p"
      }
    };
  },
  computed: {
    ...mapGetters("pondDetails", {
      // getStHourlyFeedGraph: "getStHourlyFeedGraph",
      // getStResponseGraphData: "getStResponseGraphData",
      getHourlyFeedVsResponse: "getHourlyFeedVsResponse",
      getSTGraphHourlyIntakeResponseData: "getSTGraphHourlyIntakeResponseData",
      getStGraphLoading: "getStGraphLoading",
      getStIdStDetailsOfSelectedPondId: "getStIdStDetailsOfSelectedPondId"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),

    dataViewOptionsStr() {
      return {
        "Raw Data": "Pond_raw_data",
        "Average Data": "Comn_avrg_data"
      };
    },
    chm__defaultDateObjDtRangeForCurrCulture() {
      return this.dhm__dateUtilsLib.getDateRangeFromRefDate({
        referenceDate: this.dhm__getTodayInUserTZ,
        distanceFromRefDate: [{ action: "subtract", params: [{ days: 3 }] }],
        timeZone: this.getUserTimeZoneString,
        actionsOnReferenceDate: [],
        actionsOnDateRangeItem: []
      });
    },
    pickerOptions() {
      return {
        disabledDate: this.chm__disableDateMethod
      };
    }
  },
  mounted() {
    this.upm__setDataKeys("code", "title");
    this.chm__registerResizeObserver("st-status-graph");
  },
  beforeDestroy() {
    this.chm__unRegisterResizeObserver("st-status-graph");
  },
  methods: {
    ...mapActions("pondDetails", {
      fetchHourWisePondFeed: "fetchHourWisePondFeed",
      fetchStStatsData: "fetchStStatsData",
      fetchDeviceDetailsDetailsByDate: "fetchDeviceDetailsDetailsByDate",
      fetchStAndPmLogs: "fetchStAndPmLogs",
      fetchHourlyFeedVsResponse: "fetchHourlyFeedVsResponse"
    }),
    ...mapMutations("pondDetails", {
      ST_GRAPH_LOADING: "ST_GRAPH_LOADING",
      SET_SELECTED_ST_ID: "SET_SELECTED_ST_ID",
      SET_ST_RESP_GRAPH_DATA: "SET_ST_RESP_GRAPH_DATA",
      SET_ST_OCF_GRAPH_DATA: "SET_ST_OCF_GRAPH_DATA"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent(fetchSTsFromLog) {
      await this.ST_GRAPH_LOADING(true);
      const yAxis = "PM_dispensed_feed_kg";
      try {
        // pond and device mapping log -> pass devoice type as shrimp talk -> shrimp talk
        // drop down list
        // select shrimp talk id and pass to newq api
        // shrimp talk and pond mother log
        // identify from and to time and fetch the
        if (fetchSTsFromLog) {
          await this.fetchDeviceDetailsDetailsByDate({
            pondId: this.pondId,
            device_type: "SHRIMP_TALK",
            from_date: this.dhm__dateUtilsLib.isoDayStartFormatString.replace(
              /<short-iso-date>/,
              this.params.to_date
            ),
            to_date: this.dhm__dateUtilsLib.isoDayEndFormatString.replace(
              /<short-iso-date>/,
              this.params.to_date
            )
          });
        }
        const shrimp_talks = Object.values(
          this.getStIdStDetailsOfSelectedPondId || {}
        );
        console.log("shrimp_talks", shrimp_talks);
        if (shrimp_talks.length > 0) {
          this.listShrimpTalks = this.$lodash
            .cloneDeep(shrimp_talks)
            .sort((a, b) =>
              this.$commonUtils.alphaNumericComparator(
                this.upm__getValue(a),
                this.upm__getValue(b)
              )
            );
          const stIds = shrimp_talks.map((st) => st._id);
          if (
            (!this.selectStId && this.selectStId === "") ||
            stIds.indexOf(this.selectStId) === -1
          ) {
            this.selectStId = this.listShrimpTalks[0]._id;
          }
          // add logic to call new api
          // code start
          // const s btCode = this.listShrimpTalks.filter(item => item._id === this.selectStId)[0].code;
          //  params.from_date += "T00:00:00.000Z";
          // params.to_date += "T23:59:59.999Z";
          // const fromDate = this.params.from_date + "T00:00:00.000Z";
          // const toDate = this.params.to_date + "T23:59:59.999Z";
          // try {
          //  const response = this.fetchStAndPmLogs({
          //     stId: this.selectStId,
          //     from_date: fromDate,
          //     // from_date: this.dhm__castUserUTCToUTCDateObj(new Date(this.params.from_date)),
          //     // to_date: this.dhm__castUserUTCToUTCDateObj(new Date(this.params.to_date))
          //     to_date: toDate
          //   });
          //   console.log(response);
          // } catch (err) {
          //   console.log("st and pm logs", err);
          // }
          // code end
        await this.fetchHourlyFeedVsResponse({
          from_date: this.dhm__dateUtilsLib.isoDayStartFormatString.replace(
              /<short-iso-date>/,
              this.params.to_date
            ),
          to_date: this.dhm__dateUtilsLib.isoDayEndFormatString.replace(
              /<short-iso-date>/,
              this.params.to_date
            ),
          shrimp_talk_id: this.selectStId,
          pond_id: this.pondId
        });
        this.hourlyResultData = await this.pondHourlyFeed(this.getHourlyFeedVsResponse);
          await this.initChartSeries(
            this.hourlyResultData
          );
          // this.feed_hourly_logs = [];
          await this.fetchHourWisePondFeed({
            deviceType: "shrimp_talk",
            params: this.params
          });
          // this.feed_hourly_logs = this.getStHourlyFeedGraph || [];
          await this.SET_SELECTED_ST_ID(this.selectStId);
          await this.fetchStConfigData(this.selectStId);
          await this.chm__initAxisTextKeys("Comn_date_and_time", [
            yAxis,
            "Comn_response"
          ]);
        } else {
          this.feed_hourly_logs = [];
          this.selectStId = "";
          this.SET_SELECTED_ST_ID(this.selectStId);
          this.SET_ST_RESP_GRAPH_DATA([]);
          this.SET_ST_OCF_GRAPH_DATA([]);
          this.listShrimpTalks = [];
        }
        await this.chm__initChartLang();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        if (this.$store.hasModule("pondDetails")) {
          await this.ST_GRAPH_LOADING(false);
        }
      }
    },
    parseStStatsData(stStatsData, dateRange) {
      const data = this.$lodash.cloneDeep(stStatsData);
      const dates = Object.keys(stStatsData).sort((obj1, obj2) => obj1 - obj2);
      const lastDate = dates[dates.length - 1];
      if (!lastDate) {
        this.STResponseData = [];
        return;
      }
      console.log(stStatsData);
      const padDateRanges = this.chm__padDateRanges(dates, 3600000);
      this.STResponseData = padDateRanges.map((x) => {
        if (data[x]) {
          if (data[x] < 0) {
            return [+x, 0];
          }
          return [+x, data[x]];
        }
        return [+x, 0];
      });
      console.log(this.STResponseData);
    },
    chm__setAxisText() {
      this.chartOptions.xAxis.title.text = this.$t(this.xAxisTextKey);
      this.chartOptions.yAxis[0].title.text = this.$t(
        this.chm__yAxisTextKey[0]
      );
      this.chartOptions.yAxis[1].title.text = this.$t(
        this.chm__yAxisTextKey[1]
      );
      // this.chartOptions.tooltip.xDateFormat = "%d/%m/%Y %I:%M %";
      this.chartOptions.tooltip.xDateFormat = `${
        this.dateFormatObject[this.upm__getFormatDateString]
      } ${this.timeFormatObject[this.upm__getFormatTimeString]}`;
    },
    pondHourlyFeed(pondFeedDaysWise = []) {
      const milliSecs = (date) => date.getTime();
      const dayFeedSchedule = {};
      const dayFeedAutomatic = {};
      const dayFeedingLevel = {};
      const feeding_response = {};
      pondFeedDaysWise.forEach((dayFeed) => {
        const dateISOStr = dayFeed.date;
        dayFeed.time_slots.forEach((timeSlotFeed) => {
          const correctDate = this.dhm__dateUtilsLib.parseISO(dateISOStr);
          const dateTimeFeed = milliSecs(
            this.dhm__dateUtilsLib.add(correctDate, {
              // seconds: timeSlotFeed.s_time_in_seconds
              hours: Number(timeSlotFeed.s_time.split(":")[0])
            })
          );
            if (dayFeedAutomatic[dateTimeFeed] === undefined) {
              dayFeedAutomatic[dateTimeFeed] = 0;
            }
            if (dayFeedSchedule[dateTimeFeed] === undefined) {
              dayFeedSchedule[dateTimeFeed] = 0;
            }
            if (dayFeedingLevel[dateTimeFeed] === undefined) {
              dayFeedingLevel[dateTimeFeed] = '';
            }
            if (feeding_response[dateTimeFeed] === undefined) {
              feeding_response[dateTimeFeed] = 0;
            }
            feeding_response[dateTimeFeed] += timeSlotFeed.feeding_response;
            dayFeedAutomatic[dateTimeFeed] += timeSlotFeed.AUTOMATIC_FEED;
            dayFeedSchedule[dateTimeFeed] += (timeSlotFeed.BASIC_FEED + timeSlotFeed.SCHEDULE_FEED + timeSlotFeed.NONE_FEED);
            dayFeedingLevel[dateTimeFeed] = timeSlotFeed.FEEDING_LEVEL;
        });
      });
      let arrDayFeedSchedule = Object.keys(dayFeedSchedule).map((key) => {
        return {
          x: Number(key),
          y: dayFeedSchedule[key]
        };
      });
      let arrDayFeedAutomatic = Object.keys(dayFeedAutomatic).map((key) => {
        return {
          x: Number(key),
          y: dayFeedAutomatic[key],
          Z: dayFeedingLevel[key]
        };
      });
      let arrFeedingResponse = Object.keys(dayFeedAutomatic).map((key) => {
        return {
          x: Number(key),
          y: feeding_response[key]
        };
      });
      arrFeedingResponse = arrFeedingResponse.sort((a, b) => a.x - b.x);
      arrDayFeedSchedule = arrDayFeedSchedule.sort((a, b) => a.x - b.x);
      arrDayFeedAutomatic = arrDayFeedAutomatic.sort((a, b) => a.x - b.x);
      return {
        feedSchedule: arrDayFeedSchedule,
        feedAutomatic: arrDayFeedAutomatic,
        feedingResponse: arrFeedingResponse
      };
    },
    // pondHourlyFeed(pondFeedDaysWise = [], dateRange) {
    //   const milliSecs = (date) => date.getTime();
    //   const dayFeedAutomatic = {};
    //   pondFeedDaysWise.forEach((dayFeed) => {
    //     const dateISOStr = dayFeed.date;
    //     const availableSTIds = new Set();
    //     const availableModes = new Set();
    //     const totalHourKeys = [];
    //     for (let hour = 0; hour < 24; hour++) {
    //       const correctDate = this.dhm__dateUtilsLib.parseISO(dateISOStr);
    //       const dateTimeFeed = milliSecs(
    //         this.dhm__dateUtilsLib.add(correctDate, {
    //           hours: hour
    //         })
    //       );
    //       totalHourKeys.push(dateTimeFeed);
    //     }

    //     dayFeed.time_slots.reduce((accDayFeed, eachDayHourFeed) => {
    //       const correctDate = this.dhm__dateUtilsLib.parseISO(dateISOStr);
    //       const eachDayHourInMs = milliSecs(
    //         this.dhm__dateUtilsLib.add(correctDate, {
    //           seconds: eachDayHourFeed.s_time_in_seconds
    //         })
    //       );
    //       accDayFeed[eachDayHourInMs] = eachDayHourFeed.pond_mothers.reduce(
    //         (acc, pm) => {
    //           const pmMode = ["AUTOMATIC"].includes(pm.mode)
    //             ? "AUTOMATIC"
    //             : "SCHEDULE";
    //           if (["NONE"].includes(pm.mode)) {
    //             return acc;
    //           }
    //           if (pm.shrimp_talk_id) {
    //             const stId = pm.shrimp_talk_id;
    //             availableSTIds.add(stId);
    //             availableModes.add(pmMode);
    //             if (!acc[stId]) {
    //               acc[stId] = {};
    //             }
    //             if (!acc[stId][pmMode]) {
    //               acc[stId][pmMode] = 0;
    //             }
    //             acc[stId][pmMode] += pm.dispensed_feed;
    //           }
    //           return acc;
    //         },
    //         {}
    //       );
    //       return accDayFeed;
    //     }, dayFeedAutomatic);
    //     totalHourKeys.reduce((accHours, eachHour) => {
    //       if (!accHours[eachHour]) {
    //         accHours[eachHour] = {};
    //       }
    //       [...availableSTIds].reduce((accSts, eachSt) => {
    //         [...availableModes].reduce((accModes, mode) => {
    //           if (!accSts[eachSt]) {
    //             accSts[eachSt] = {};
    //           }
    //           if (!accSts[eachSt][mode]) {
    //             accSts[eachSt][mode] = undefined;
    //           }
    //           return accModes;
    //         }, accSts[eachSt]);
    //         return accSts;
    //       }, accHours[eachHour]);
    //       return accHours;
    //     }, dayFeedAutomatic);
    //   });

    //   const groupBySts = {};
    //   Object.keys(dayFeedAutomatic).forEach((key) => {
    //     const stIds = Object.keys(dayFeedAutomatic[key]);
    //     stIds.forEach((stId) => {
    //       const modes = Object.keys(dayFeedAutomatic[key][stId]);
    //       modes.forEach((mode) => {
    //         if (typeof groupBySts[stId] === "undefined") {
    //           groupBySts[stId] = [];
    //         }
    //         groupBySts[stId].push({
    //           x: Number(key),
    //           y: dayFeedAutomatic[key][stId][mode],
    //           mode
    //         });
    //       });
    //     });
    //   });
    //   Object.keys(groupBySts).map((key) => {
    //     groupBySts[key].sort((a, b) => a.x - b.x);
    //   });
    //   this.groupByStsId = groupBySts;
    // },
    handleValueChange: async function(currentSelectedValues, type = '') {
      if (Array.isArray(currentSelectedValues)) {
        this.params.from_date = currentSelectedValues[1];
        this.params.to_date = currentSelectedValues[1];
      } else {
        this.params.from_date = currentSelectedValues;
        this.params.to_date = currentSelectedValues;
      }
      await this.initComponent(true);
      if (type === 'dateChange') {
        this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - Hourly Feed vs Response Graph - Date Filter" });
      }
    },
    handleChangeInSt: async function(stId) {
      try {
        await this.ST_GRAPH_LOADING(true);
        this.SET_SELECTED_ST_ID(stId);
        if (this.groupByStsId[stId] || this.STResponseData) {
          // await this.fetchStConfigData(stId);
          // this.parseStStatsData(
          //   this.getSTGraphHourlyIntakeResponseData,
          //   this.dateRange
          // );
          await this.initComponent(false);
          // this.initChartSeries(
          //   this.hourlyResultData
          // );
        } else {
          this.initChartSeries({});
        }
        await this.ST_GRAPH_LOADING(false);
        this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - Hourly Feed vs Response Graph - ShrimpTalk - Dropdown" });
      } catch (err) {
        this.ehm__errorMessages(err, true);
        await this.ST_GRAPH_LOADING(false);
      }
    },
    async fetchStConfigData(stId) {
      const selectedStObj = this.listShrimpTalks.filter(
        (item) => item._id === this.selectStId
      )[0];
      const params = { ...this.params };
      if (
        dateUtils.isAfter(
          new Date(params.from_date),
          new Date(selectedStObj.added_date)
        ) &&
        !selectedStObj.hasOwnProperty("removed_date")
      ) {
        params.from_date += "T00:00:00.000Z";
        params.to_date += "T23:59:59.999Z";
      } else if (
        dateUtils.isAfter(
          new Date(params.from_date),
          new Date(selectedStObj.added_date)
        ) &&
        selectedStObj.hasOwnProperty("removed_date")
      ) {
        params.from_date += "T00:00:00.000Z";
        if (
          dateUtils.isAfter(
            new Date(selectedStObj.removed_date),
            new Date(params.to_date)
          )
        ) {
          params.to_date += "T23:59:59.999Z";
        } else {
          params.to_date = selectedStObj.removed_date;
        }
      } else if (
        dateUtils.isBefore(
          new Date(params.from_date),
          new Date(selectedStObj.added_date)
        ) &&
        !selectedStObj.hasOwnProperty("removed_date")
      ) {
        params.from_date = selectedStObj.added_date;
        params.to_date += "T23:59:59.999Z";
      } else if (
        dateUtils.isBefore(
          new Date(params.from_date),
          new Date(selectedStObj.added_date)
        ) &&
        selectedStObj.hasOwnProperty("removed_date")
      ) {
        params.from_date = selectedStObj.added_date;
        if (
          dateUtils.isAfter(
            new Date(selectedStObj.removed_date),
            new Date(params.to_date)
          )
        ) {
          params.to_date += "T23:59:59.999Z";
        } else {
          params.to_date = selectedStObj.removed_date;
        }
      } else if (
        dateUtils.isEqual(
          new Date(params.from_date),
          new Date(selectedStObj.added_date)
        )
      ) {
        if (!selectedStObj.hasOwnProperty("removed_date")) {
          params.from_date = selectedStObj.added_date;
          params.to_date += "T23:59:59.999Z";
        } else if (selectedStObj.hasOwnProperty("removed_date")) {
          if (
            dateUtils.isAfter(
              new Date(selectedStObj.removed_date),
              new Date(params.to_date)
            )
          ) {
            params.from_date = selectedStObj.added_date;
            params.to_date += "T23:59:59.999Z";
          } else {
            params.from_date = selectedStObj.added_date;
            params.to_date = selectedStObj.removed_date;
          }
        }
      }
    //   console.log("selectedStObj", selectedStObj);
      await this.fetchStStatsData({ stId, params, graph: "ST_HOURLY" });
    },
    async chm__initChart() {
      // await this.initComponent(true);
      // let stFeedData = [];
      // let stResponseData = [];
      // if (
      //   this.feed_hourly_logs.length === 0 ||
      //   (this.getStResponseGraphData || []).length === 0
      // ) {
      //   this.chartOptions.series = [];
      //   return;
      // }
      // this.pondHourlyFeed(
      //   this.feed_hourly_logs,
      //   this.dateRange,
      //   this.selectStId
      // );
      // this.parseStStatsData(
      //   this.getSTGraphHourlyIntakeResponseData,
      //   this.dateRange
      // );
      // stFeedData = this.groupByStsId[this.selectStId] || [];
      // stResponseData = this.STResponseData || [];
      await this.initChartSeries(this.hourlyResultData);
    },
    initChartSeries(stFeedAndResponseData) {
      this.chartOptions.series = [];
        if (stFeedAndResponseData.feedAutomatic.length === 0 && stFeedAndResponseData.feedSchedule.length === 0 && stFeedAndResponseData.feedingResponse.length === 0) {
        return;
      }
        // const modeWiseGrouped = stFeedData.reduce((acc, st) => {
        //   if (!acc[st.mode]) {
        //     acc[st.mode] = [];
        //   }
        //   acc[st.mode].push(st);
        //   return acc;
        // }, {});
        // Object.keys(modeWiseGrouped).forEach((mode) => {
        //   const modeItem = generateColorForModeInGraph(mode);
        //   if (mode !== undefined && mode === "SCHEDULE") {
        //     modeItem.translation = this.$t("Comn_other");
        //   }
        //   this.chartOptions.series.push({
        //     name: modeItem.translation,
        //     data: modeWiseGrouped[mode],
        //     color: modeItem.color,
        //     type: "column",
        //     pointWidth: 10,
        //     stacking: "overlap",
        //     yAxis: 0,
        //     stack: 0,
        //     dataGrouping: {
        //       enabled: false
        //     },
        //     visible: true
        //   });
        // });
        this.chartOptions.series.push({
        // name: this.$t("PM_automatic"),
        name: {
          legend: this.$t("PM_automatic"),
          tooltip: this.$t("PM_automatic"),
          tooltipName: this.$t('Comn_feeding_level'),
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: "<small>{point.key}</small><table>",
          pointFormat:
            '<tr><td style="color: {series.color}">{series.name.tooltipName}: </td>' +
            '<td style="text-align: right"><b>{point.Z}</b></td></tr><tr><td style="color: {series.color}">{series.name.tooltip}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          valueDecimals: 2,
          split: false,
        },
        data: stFeedAndResponseData.feedAutomatic,
        color: "#758e32",
        type: "column",
        pointWidth: 10,
        visible: true,
        stacking: "overlap",
        stack: 0,
        dataGrouping: {
          enabled: false
        }
      });
      this.chartOptions.series.push({
        // name: this.$t("Comn_other"),
        name: {
          legend: this.$t("Comn_other"),
          tooltip: this.$t("Comn_other"),
          // tooltipName: this.$t('Comn_feeding_level'),
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: "<small>{point.key}</small><table>",
          pointFormat:
            // '<tr><td style="color: {series.color}">{series.name.tooltipName}: </td>' +<td style="text-align: right"><b>{point.Z}</b></td>
            '</tr><tr><td style="color: {series.color}">{series.name.tooltip}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          valueDecimals: 2,
          split: false,
        },
        data: stFeedAndResponseData.feedSchedule,
        color: "#f1b727",
        type: "column",
        pointWidth: 10,
        visible: true,
        stacking: "overlap",
        stack: 0,
        dataGrouping: {
          enabled: false
        }
      });
      if (stFeedAndResponseData.feedingResponse.length > 0) {
        this.chartOptions.series.push({
          // name: this.$t("Comn_feeding_response"),
          name: {
            legend: this.$t("Comn_feeding_response"),
            tooltip: this.$t("Comn_feeding_response")
          },
          type: "spline",
          yAxis: 1,
          data: stFeedAndResponseData.feedingResponse,
          tooltip: {
            valueSuffix: " "
          },
          color: "#7394cb",
          dataGrouping: {
            enabled: false
          },
          visible: true
        });
      }
    }
  }
};
</script>
<style lang="scss">
.st-status-graph {
  .el-card__header .date-picker-wrapper {
    @include responsiveProperty(flex-basis, 15%, 12%, 9%);
    width: 140px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border: 1px solid #acc9f4;
    border-radius: 4px;

    &.el-input--prefix .el-input__inner,
    &.el-input--suffix .el-input__inner {
      padding: unset;
    }
    .el-input__prefix {
      position: relative;
      left: unset;
      top: unset;
      display: inline-flex;
      align-items: center;
      order: 1;
    }
    .el-input__suffix {
      display: none;
    }
    .el-input__inner {
      // width: 80%;
      // padding-right: 10px;
      font-size: $app_font_size_1;
      line-height: 26px;
      height: 26px;
      order: 2;
      border: unset;
    }
    .el-input__icon {
      line-height: 1.15;
      margin-left: -5px;
      font-size: $app_font_size_1;
      // @include icon-size(icon-font, prefix);
    }
  }
  .el-select .el-input__inner {
    cursor: pointer;
    margin-right: 15px;
    padding-right: 35px;
  }

  .el-input--suffix .el-input__inner {
    // padding-right: 18px;
    text-align: center;
  }
}
</style>
